import React from "react"

// FireCMS
import { type FieldProps, useDataSource, type Entity } from "@firecms/core"
import { ExpandablePanel, RuleIcon, Typography } from "@firecms/ui"
import { QuestionsCollection } from "../../collections/questions"

// Types
import type { ADMIN } from "types"

// Utils
import { serializeJsonLogic, deserializeJsonLogic } from "utils/jsonLogicUtils"

// react-querybuilder
import QueryBuilder, { formatQuery, RQBJsonLogic, type RuleGroupType } from "react-querybuilder"
import { parseJsonLogic } from "react-querybuilder/parseJsonLogic"
import "react-querybuilder/dist/query-builder-layout.css"

// Custom Components
import useConfiguredFields from "./useConfiguredFields"
import CodeBlock from "../CodeBlock"
import CustomValueEditor from "./CustomValueEditor"
import CustomValueSelector from "./CustomValueSelector"
import CustomActionElement from "./CustomActionElement"
import CustomRemoveAction from "./CustomRemoveAction"
import CustomRuleProcessor from "./CustomRuleProcessor"
import getOperators from "./getOperators"

const LogicBuilder: React.FC<FieldProps<string>> = ({ value, setValue, customProps, error: LogicError }) => {
	// QueryBuilder props
	const [question, setQuestion] = React.useState<Entity<ADMIN.Question> | undefined>()
	const fields = useConfiguredFields(question)

	// Deserialize JSON Logic once
	const deserializedValue = React.useMemo(() => deserializeJsonLogic(value), [value])

	// GET REFERENCE DOC
	// Hook to fetch the question Document
	const { fetchEntity } = useDataSource()

	const path = customProps?.question?.path
	const entityId = customProps?.question?.id

	// Combine useEffect with useCallback for async functions
	// https://stackoverflow.com/a/53572588
	const fetchReferenceDocument = React.useCallback(async () => {
		// Don't try to fetch the reference document if path or entityId are undefined.
		if (!path || !entityId) return

		try {
			const response = await fetchEntity({
				path,
				entityId,
				collection: QuestionsCollection,
			})
			setQuestion(response)
		} catch (error) {
			throw new Error(
				`Couldn't fetch reference document using this reference - path:${path} entityId:${entityId}`
			)
		}
	}, [path, entityId, fetchEntity])

	React.useEffect(() => {
		fetchReferenceDocument()
	}, [fetchReferenceDocument])

	// SAVE LOGIC
	const handleSetQuery = (newQuery: RuleGroupType) => {
		// Format query to universal JSONLogic
		const formattedQuery = formatQuery(newQuery, {
			format: "jsonlogic",
			parseNumbers: true,
			ruleProcessor: CustomRuleProcessor,
		})

		// convert JSONLogic to string
		// @ts-ignore <React Query-Builder and JsonLogic don't quite match up>
		const stringifiedQuery = serializeJsonLogic(formattedQuery)

		// Catch any errors from the conversion
		if (!stringifiedQuery.success) {
			setValue("")
			console.error("Error serializing JSON Logic:", stringifiedQuery.error)
			return
		}

		// Update the value property
		setValue(stringifiedQuery.data || null)
	}

	return (
		<ExpandablePanel title={<Title />} asField={false} initiallyExpanded={false}>
			<QueryBuilder
				fields={fields}
				defaultQuery={parseJsonLogic(value)}
				getOperators={getOperators}
				onQueryChange={handleSetQuery}
				controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
				controlElements={{
					// notToggle: () => null,
					valueEditor: CustomValueEditor,
					valueSelector: CustomValueSelector,
					fieldSelector: CustomValueSelector, // hide this element
					actionElement: CustomActionElement,
					removeGroupAction: CustomRemoveAction,
					removeRuleAction: CustomRemoveAction,
				}}
			/>
			<CodeBlock value={deserializedValue.success ? deserializedValue.data : null} />
			{LogicError && (
				<Typography variant="caption" color="error" className="p-2">
					Error: {LogicError}
				</Typography>
			)}
			{!deserializedValue.success && (
				<Typography variant="caption" color="error" className="p-2">
					Error: {deserializedValue.error}
				</Typography>
			)}
		</ExpandablePanel>
	)
}

export default LogicBuilder

const Title: React.FC = () => (
	<div className="flex items-center gap-2">
		<Typography variant="label" color="secondary">
			<RuleIcon size="small" />
		</Typography>
		<Typography variant="label" color="secondary">
			Conditions
		</Typography>
	</div>
)
