import type { AdditionalFieldDelegate } from "@firecms/core"
import { CircularProgress, WarningIcon } from "@firecms/ui"
import useDuplicateCheck from "../../utils/useDuplicateCheck"

export const DuplicateCheckCaseNumber: AdditionalFieldDelegate<any> = {
	key: "duplicate",
	name: "Is Duplicate?",
	dependencies: ["name"],
	Builder: ({ entity }) => {
		const [isUnique, loading] = useDuplicateCheck(entity.path, "caseNumber", entity.values.caseNumber, entity.id)

		return loading ? <CircularProgress size="small" /> : isUnique ? null : <WarningIcon size={16} color="warning" />
	},
}
