import type { AdditionalFieldDelegate, AdditionalFieldDelegateProps } from "@firecms/core"
import { AdminPreview } from "../../components/AdminPreview"
import type { ADMIN } from "types"

export const AdminField: AdditionalFieldDelegate<ADMIN.User> = {
	// @ts-ignore
	id: "admin",
	name: "Admin Status",
	dependencies: ["uid"],
	Builder: (props: AdditionalFieldDelegateProps) => <AdminPreview {...props} />,
}
