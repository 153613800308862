import type React from "react"
import { CloseIcon, IconButton } from "@firecms/ui"
import type { ActionProps } from "react-querybuilder"

const CustomRemoveAction: React.FC<ActionProps> = props => {
	return (
		<IconButton
			{...props}
			className="ms-auto"
			onClick={props.handleOnClick}
			size="small"
			variant="filled"
			shape="square"
		>
			<CloseIcon />
		</IconButton>
	)
}
export default CustomRemoveAction
