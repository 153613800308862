import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import DateCreated from "../../properties/DateCreated"
import { _SettlementsCollection } from "../_settlements"

export const Page = buildCollection<ADMIN.Page>({
	id: "firecrawlPage",
	name: "Page",
	singularName: "Page",
	path: "pages",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		markdown: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const Firecrawl = buildCollection<ADMIN.Firecrawl>({
	id: "firecrawl",
	name: "Firecrawl",
	singularName: "Firecrawl",
	path: "firecrawl",
	description: "A history of the crawled settlement website",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	subcollections: [Page],
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
	},
})

export const OptimizedContent = buildCollection<ADMIN.OptimizedContent>({
	id: "optimizedContent",
	name: "Optimized",
	singularName: "Optimized",
	path: "optimizedContent",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		optimizedContent: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const LongSummaryCollection = buildCollection<ADMIN.LongSummaryCollection>({
	id: "longSummaryCollection",
	name: "Long Summary",
	singularName: "Long Summary",
	path: "longSummaryCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		longSummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const ShortSummaryCollection = buildCollection<ADMIN.ShortSummaryCollection>({
	id: "shortSummaryCollection",
	name: "Short Summary",
	singularName: "ShortSummary",
	path: "shortSummaryCollection",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		shortSummary: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const Requirements = buildCollection<ADMIN.Requirements>({
	id: "requirements",
	name: "Requirements",
	singularName: "Requirements",
	path: "requirements",
	defaultSize: "xs",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		requirements: {
			dataType: "string",
			markdown: true,
			readOnly: true,
		},
	},
})

export const Core = buildCollection<ADMIN.Core>({
	id: "core",
	name: "Core",
	singularName: "Core",
	path: "core",
	description: "The base settlement object (without the form)",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		settlement: {
			name: "Settlement Object",
			dataType: "map",
			keyValue: true,
			readOnly: true,
		},
	},
})

export const FormQuestions = buildCollection<ADMIN.FormQuestions>({
	id: "formQuestions",
	name: "Form Questions",
	singularName: "Form Questions",
	path: "formQuestions",
	description: "A list of questions used to determine eligibility",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		form: {
			name: "form",
			dataType: "array",
			readOnly: true,
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
	},
})

export const FormLogic = buildCollection<ADMIN.FormLogic>({
	id: "formLogic",
	name: "Form Logic",
	singularName: "Form Logic",
	path: "formLogic",
	description: "The form with logic added",
	defaultSize: "xl",
	initialSort: ["dateCreated", "desc"],
	selectionEnabled: false,
	permissions: () => ({
		read: true,
		edit: true,
		create: false,
		delete: true,
	}),
	//@ts-ignore
	properties: {
		dateCreated: DateCreated,
		form: {
			name: "form",
			dataType: "array",
			readOnly: true,
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
	},
})

export const Complete = buildCollection<ADMIN.Settlement>({
	..._SettlementsCollection,
	id: "complete",
	name: "Complete",
	singularName: "Complete",
	description: "A test object all the steps are written to",
	path: "complete",
	initialSort: ["dateUpdated", "desc"],
	selectionEnabled: false,
	defaultSize: "xs",
	permissions: () => ({
		read: true,
		edit: false,
		create: false,
		delete: true,
	}),
	properties: {
		..._SettlementsCollection.properties,
		cases: {
			name: "Cases",
			dataType: "array",
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
		defendants: {
			name: "Defendants",
			dataType: "array",
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
		brands: {
			name: "Brands",
			dataType: "array",
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
		firms: {
			name: "Firms",
			dataType: "array",
			of: {
				dataType: "map",
				keyValue: true,
			},
		},
	},
})
