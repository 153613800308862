import type React from "react"
import { Button } from "@firecms/ui"
import type { ActionProps } from "react-querybuilder"

const CustomActionElement: React.FC<ActionProps> = ({ handleOnClick, label, ...props }) => {
	return (
		<Button {...props} onClick={handleOnClick} size="small">
			{label}
		</Button>
	)
}
export default CustomActionElement
