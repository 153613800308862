import type { Entity } from "@firecms/core"
import type { FullField } from "react-querybuilder"
import type { ADMIN } from "types"

type fieldConfigType = (question: Entity<ADMIN.Question>) => FullField[]

// @ts-ignore
const fieldConfig: fieldConfigType = question => {
	const { id, values } = question
	const type = values?.type

	// create a new field based on the question type
	// https://react-querybuilder.js.org/api/modules/react_querybuilder#valueeditortype
	console.log("type", type)
	switch (type) {
		case "yesNo":
			return [
				{
					id,
					name: id,
					label: "answer",
					valueEditorType: "checkbox",
					inputType: "checkbox",
					questionType: type,
				},
			]
		case "singleChoiceSelect":
		case "singleChoiceRadio":
		case "multipleChoiceCheckboxes":
			return [
				{
					id,
					name: id,
					label: "answer",
					valueEditorType: "select",
					inputType: "select",
					questionType: type,
					// @ts-ignore
					values: question?.values?.options?.map((o: ADMIN.Question["options"]) => ({
						name: o.value,
						value: o.value,
						label: o.label,
					})),
				},
			]
		default:
			return [
				{
					id,
					name: id,
					label: "answer",
					valueEditorType: "text",
					inputType: "text",
					questionType: type,
				},
			]
	}
}

export default fieldConfig
