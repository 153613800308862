import type React from "react"
import { Select, SelectItem } from "@firecms/ui"
import type { FullOption, OptionGroup, ValueSelectorProps } from "react-querybuilder"

const CustomValueSelector: React.FC<ValueSelectorProps<FullOption>> = ({
	value,
	handleOnChange,
	options,
	...props
}) => {
	const getLabelFromValue = (optionValue: string) => {
		let label = "Choose an Option"

		options.forEach(option => {
			if ((option as OptionGroup<FullOption>).options) {
				// It's an OptionGroup
				const optGroup = option as OptionGroup<FullOption>
				const foundOption = optGroup.options.find((op: FullOption) => op.value === optionValue)
				if (foundOption) {
					label = foundOption.label
				}
			} else {
				// It's a FullOption
				const fullOption = option as FullOption
				if (fullOption.value === optionValue) {
					label = fullOption.label
				}
			}
		})

		return label
	}

	return (
		<Select
			{...props}
			value={value}
			onValueChange={handleOnChange}
			renderValue={selectedValue => getLabelFromValue(selectedValue)}
			placeholder="Choose an Option"
			size="small"
		>
			{options.map(
				(
					{
						// @ts-ignore
						name: opName,
						// @ts-ignore
						value: opValue,
						label: opLabel,
					},
					i
				) => (
					<SelectItem key={`${opName}.${i}`} value={opValue}>
						{opLabel}
					</SelectItem>
				)
			)}
		</Select>
	)
}
export default CustomValueSelector
