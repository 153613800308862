import { defaultRuleProcessorJsonLogic, type RuleProcessor } from "react-querybuilder"

const CustomRuleProcessor: RuleProcessor = (rule, options, meta) => {
	const { field, value, operator } = rule

	switch (operator) {
		case "in":
			return {
				in: [value, { var: field }],
			}
		case "notIn":
			return {
				"!": { in: [value, { var: field }] },
			}
		default:
			return defaultRuleProcessorJsonLogic(rule, options, meta)
	}
}

export default CustomRuleProcessor
