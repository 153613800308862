import type { RulesLogic } from "json-logic-js"

type Result<T> =
	| {
			success: true
			data: T
	  }
	| {
			success: false
			error: string
	  }

export const deserializeJsonLogic = (stringLogic: string): Result<RulesLogic> => {
	if (typeof stringLogic !== "string") {
		return { success: false, error: "Input must be a string" }
	}

	try {
		const deserialized = JSON.parse(stringLogic)

		// Add validation here if needed, e.g.:
		// if (!isValidRulesLogic(deserialized)) {
		//   return { success: false, error: 'Invalid RulesLogic structure' };
		// }

		return { success: true, data: deserialized }
	} catch (error) {
		console.error("Error deserializing JSON Logic:", error)
		return {
			success: false,
			error: `Failed to parse JSON: ${error instanceof Error ? error.message : String(error)}`,
		}
	}
}

export const serializeJsonLogic = (jsonLogic: RulesLogic): Result<string> => {
	if (typeof jsonLogic !== "object" || jsonLogic === null) {
		return { success: false, error: "Input must be a non-null object" }
	}

	try {
		const serialized = JSON.stringify(jsonLogic)
		return { success: true, data: serialized }
	} catch (error) {
		console.error("Error serializing JSON Logic:", error)
		return {
			success: false,
			error: `Failed to stringify object: ${error instanceof Error ? error.message : String(error)}`,
		}
	}
}
