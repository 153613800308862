import { useState, useEffect } from "react"
import { useDataSource } from "@firecms/core"
import type { ADMIN } from "types"
import stringComparison from "string-comparison"

const dice = stringComparison.diceCoefficient

const useDuplicateCheck = (collectionPath: string, field: string, value: string, entityId?: string) => {
	const { fetchCollection } = useDataSource()
	const [isUnique, setIsUnique] = useState<boolean | undefined>()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const testForDuplicate = async () => {
			try {
				const entities = await fetchCollection<ADMIN.Defendant[]>({
					path: collectionPath,
				})
				const isDuplicate = entities.some(entity => {
					if (entity.id === entityId) return false
					// @ts-ignore
					return dice.similarity(entity.values[field], value) > 0.8
				})
				setIsUnique(!isDuplicate)
				setLoading(false)
			} catch (error) {
				console.error("Failed to check duplicate status:", error)
				setLoading(false)
			}
		}
		testForDuplicate()
	}, [collectionPath, field, value, entityId])

	return [isUnique, loading] as const
}

export default useDuplicateCheck
