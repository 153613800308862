import { useCallback, useMemo } from "react"

import "typeface-rubik"
import "@fontsource/jetbrains-mono"
import {
	AppBar,
	type Authenticator,
	CircularProgressCenter,
	type CMSView,
	Drawer,
	FireCMS,
	ModeControllerProvider,
	NavigationRoutes,
	Scaffold,
	SideDialogs,
	SnackbarProvider,
	useBuildLocalConfigurationPersistence,
	useBuildModeController,
	useBuildNavigationController,
	useValidateAuthenticator,
} from "@firecms/core"
import {
	type FirebaseAuthController,
	FirebaseLoginView,
	type FirebaseSignInProvider,
	type FirebaseUserWrapper,
	useFirebaseAuthController,
	useFirebaseStorageSource,
	useFirestoreDelegate,
	useInitialiseFirebase,
} from "@firecms/firebase"
import { useFirestoreUserManagement } from "@firecms/user_management"
import { useImportExportPlugin } from "@firecms/data_import_export"
import { firebaseConfig } from "./firebase_config"

// Collections
import { _SettlementsCollection } from "./collections/_settlements"
import { _CasesCollection } from "./collections/_cases"
import { _FirmsCollection } from "./collections/_firms"
import { _DefendantsCollection } from "./collections/_defendants"
import { _BrandsCollection } from "./collections/_brands"
import { SettlementsCollection } from "./collections/settlements"
import { UsersCollection } from "./collections/users"
import { ExtractCollection } from "./collections/extract"
import { QuestionsCollection } from "./collections/questions"
import { Legal } from "./collections/legal"

// Views
import { DuplicateView } from "./views/DuplicateView"

// Utils
import textSearchController from "./utils/textSearchController"

export function App() {
	const { firebaseApp, firebaseConfigLoading, configError } = useInitialiseFirebase({
		firebaseConfig,
	})

	const collectionsBuilder = useCallback(() => {
		// Here we define a sample collection in code.
		const collections = [
			_SettlementsCollection,
			_CasesCollection,
			_DefendantsCollection,
			_BrandsCollection,
			_FirmsCollection,
			SettlementsCollection,
			QuestionsCollection,
			UsersCollection,
			ExtractCollection,
			Legal,
			// Your collections here
		]
		// You can merge collections defined in the collection editor (UI) with your own collections
		return collections
	}, [])

	/**
	 * Controller used to manage the dark or light color mode
	 */
	const modeController = useBuildModeController()

	/**
	 * Controller in charge of user management
	 */
	const userManagement = useFirestoreUserManagement({
		firebaseApp,
	})

	// Here you define your custom top-level views
	const views: CMSView[] = useMemo(
		() => [
			{
				path: "example",
				name: "Merge Duplicates",
				group: "tools",
				icon: "merge",
				view: <DuplicateView />,
			},
		],
		[]
	)

	const signInOptions: FirebaseSignInProvider[] = ["google.com"]
	/**
	 * Controller for managing authentication
	 */
	const authController: FirebaseAuthController = useFirebaseAuthController({
		firebaseApp,
		signInOptions,
		loading: userManagement.loading,
		defineRolesFor: userManagement.defineRolesFor,
	})

	/**
	 * Controller for saving some user preferences locally.
	 */
	const userConfigPersistence = useBuildLocalConfigurationPersistence()

	/**
	 * Delegate used for fetching and saving data in Firestore
	 */
	const firestoreDelegate = useFirestoreDelegate({
		firebaseApp,
		localTextSearchEnabled: true,
		textSearchControllerBuilder: textSearchController,
	})

	/**
	 * Controller used for saving and fetching files in storage
	 */
	const storageSource = useFirebaseStorageSource({
		firebaseApp,
	})

	/**
	 * The custom authenticator checks the user's custom claims for an `admin` property
	 */
	const claimGemAuthenticator: Authenticator<FirebaseUserWrapper> = useCallback(async ({ user }) => {
		if (user?.uid == null) throw Error("User is not logged in!")
		const idTokenResult = await user?.firebaseUser?.getIdTokenResult()
		if (idTokenResult?.claims.admin) return true
		return false
	}, [])

	/**
	 * Use the authenticator to control access to the main view
	 */
	const { authLoading, canAccessMainView, notAllowedError } = useValidateAuthenticator({
		authController,
		disabled: userManagement.loading,
		authenticator: claimGemAuthenticator, // you can define your own authenticator here
		dataSourceDelegate: firestoreDelegate,
		storageSource,
	})

	const navigationController = useBuildNavigationController({
		collections: collectionsBuilder,
		collectionPermissions: userManagement.collectionPermissions,
		views,
		authController,
		dataSourceDelegate: firestoreDelegate,
	})

	/**
	 * Allow import and export data plugin
	 */
	const importExportPlugin = useImportExportPlugin()

	if (firebaseConfigLoading || !firebaseApp) {
		return <CircularProgressCenter />
	}

	if (configError) {
		return <>{configError}</>
	}

	return (
		<SnackbarProvider>
			<ModeControllerProvider value={modeController}>
				<FireCMS
					navigationController={navigationController}
					authController={authController}
					userConfigPersistence={userConfigPersistence}
					dataSourceDelegate={firestoreDelegate}
					storageSource={storageSource}
					plugins={[importExportPlugin]}
				>
					{({ context, loading }) => {
						let component
						if (loading || authLoading) {
							component = <CircularProgressCenter size="large" />
						} else {
							if (!canAccessMainView) {
								component = (
									<FirebaseLoginView
										logo="/logo.png"
										allowSkipLogin={false}
										signInOptions={signInOptions}
										firebaseApp={firebaseApp}
										authController={authController}
										notAllowedError={notAllowedError}
									/>
								)
							} else {
								component = (
									<Scaffold logo="/logo.png">
										<AppBar title="ClaimGem Admin" />
										<Drawer />
										<NavigationRoutes />
										<SideDialogs />
									</Scaffold>
								)
							}
						}

						return component
					}}
				</FireCMS>
			</ModeControllerProvider>
		</SnackbarProvider>
	)
}
