import { useState, useEffect } from "react"
import type { Field } from "react-querybuilder"
import type { Entity } from "@firecms/core"
import fieldConfig from "../FieldConfig"
import type { ADMIN } from "types"

export const useConfiguredFields = (question: Entity<ADMIN.Question> | undefined) => {
	const [fields, setFields] = useState<Field[]>([
		{ name: "Initial State", label: "Initial State", value: "Initial Value", inputType: "text" },
	])

	// CONFIGURE FIELD OPTIONS BASED ON THE QUESTION TYPE & OPTIONS
	useEffect(() => {
		// Don't create custom fields if question is undefined
		if (!question) return

		// Create the custom field based on the question.
		// For example: Yes_no question becomes a react-query-builder boolean
		const fieldOptions = fieldConfig(question)

		// Update the component's field config
		setFields(fieldOptions)
	}, [question])

	return fields
}

export default useConfiguredFields
