import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

// Development Mode
export const firebaseConfig = {
	apiKey: "AIzaSyA2N3pTs4KHPp3v2HNvH1wZHdqc1PP3hIQ",
	authDomain: "claimgem-dev.firebaseapp.com",
	projectId: "claimgem-dev",
	storageBucket: "claimgem-dev.appspot.com",
	messagingSenderId: "581413764047",
	appId: "1:581413764047:web:62b1d349342d391fbcf0ba",
	measurementId: "G-RX9KBX0RN9",
}

// Production Mode
// export const firebaseConfig = {
// 	apiKey: "AIzaSyC1LMTY4WCzwz2L0gNTNrTfWzMmaWfcIU0",
// 	authDomain: "claimgem-prod.firebaseapp.com",
// 	projectId: "claimgem-prod",
// 	storageBucket: "claimgem-prod.appspot.com",
// 	messagingSenderId: "570875467619",
// 	appId: "1:570875467619:web:537bf553105af1d51e2556",
// 	measurementId: "G-GW8TDDQNCK",
// }

const app = initializeApp(firebaseConfig)

const db = getFirestore(app)

export { db }
