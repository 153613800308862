import { buildProperty } from "@firecms/core"
import type { Domain } from "types/Enums/Domain"

const DomainProperty = buildProperty<Domain>({
	name: "Domain",
	dataType: "string",
	description: "https://docs.google.com/document/d/1bnOyczEOvSzVn9cSqWYM_QiPg5EwG0uLx7kLRq1_RlQ/edit?usp=sharing",
	enumValues: {
		location: "Location",
		employmentAndContractors: "Employment & Contractors",
		businessOwners: "Business Ownership",
		automotiveAndTransportation: "Automotive & Transportation",
		financial: "Financial",
		insurance: "Insurance",
		health: "Health",
		housing: "Housing",
		techAndMedia: "Tech & Media",
		consumerGoodsAndServices: "Consumer Goods & Services",
		other: "Other",
	},
})

export default DomainProperty
