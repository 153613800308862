import type React from "react"
import { useState } from "react"
import type { Entity } from "@firecms/core"
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	Typography,
	RadioGroup,
	RadioGroupItem,
	Label,
} from "@firecms/ui"

interface MergeDialogProps {
	open: boolean
	onClose: () => void
	entities: Entity<any>[]
	onSelectPrimary: (primaryId: Entity<any>) => void
}

const MergeDialog: React.FC<MergeDialogProps> = ({ open, onClose, entities, onSelectPrimary }) => {
	const [primaryEntity, setPrimaryEntity] = useState<Entity<any> | null>(null)

	const handlePrimaryChange = (value: string) => {
		const selectedId = entities.find(entity => entity.id === value)
		if (selectedId) {
			setPrimaryEntity(selectedId)
		}
	}

	const handleMerge = () => {
		if (primaryEntity) {
			onSelectPrimary(primaryEntity)
		}
	}

	return (
		<Dialog open={open}>
			<DialogContent>
				<Typography variant="h4" className="mb-1">
					Select Primary
				</Typography>
				<Typography className="mb-4">Choose the duplicate you want to keep.</Typography>
				<RadioGroup onValueChange={handlePrimaryChange}>
					{entities.map(entity => {
						console.log(entity)
						return (
							<Label key={entity.id} className="flex items-center">
								<RadioGroupItem value={entity.id} />
								<div className="ms-3 font-normal">
									{entity.values.name} ({entity.id})
								</div>
							</Label>
						)
					})}
				</RadioGroup>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="error" variant="outlined">
					Cancel
				</Button>
				<Button onClick={handleMerge} disabled={!primaryEntity} color="primary">
					Merge
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default MergeDialog
