import type { DataSource, Entity } from "@firecms/core"

const DEBUG = false

export const updateReferences = async (
	dataSource: DataSource,
	selectedEntities: Entity<any>[],
	primaryEntity: Entity<any>,
	fieldToUpdate: string,
	collectionPath: string
) => {
	const { fetchCollection, saveEntity } = dataSource

	// Fetch documents where the entities are referenced
	const settlementsSnapshot = await fetchCollection({
		path: "_settlements",
	})

	// DEBUG && console.log("dataSource:", dataSource)
	DEBUG && console.log("selectedEntities:", selectedEntities)
	DEBUG && console.log("primaryEntity:", primaryEntity)
	DEBUG && console.log("fieldToUpdate:", fieldToUpdate)
	DEBUG && console.log("collectionPath:", collectionPath)

	// Update references to the primary entity
	for (const docSnapshot of settlementsSnapshot) {
		const data = docSnapshot.values
		let updated = false

		DEBUG && console.log("data:", data)

		// Check if the specified field reference exists and needs to be updated
		if (!data || !Array.isArray(data[fieldToUpdate])) {
			continue
		}

		DEBUG && console.log(fieldToUpdate, "Exists and is an array")
		data[fieldToUpdate] = data[fieldToUpdate].map((item: any) => {
			if (selectedEntities.some(entity => entity.id === item.id)) {
				DEBUG && console.log("Found a reference to update", item)

				item.id = primaryEntity.id
				updated = true
			}
			return item
		})

		if (!updated) {
			continue
		}

		await saveEntity({
			path: "_settlements",
			entityId: docSnapshot.id,
			values: data,
			status: "existing",
		})
	}
}
