import { useEffect } from "react"
import { CancelIcon, CheckCircleIcon, CircularProgress } from "@firecms/ui"
import type { AdditionalFieldDelegateProps } from "@firecms/core"
import { useAdminStatus } from "../../utils/useAdminStatus"

export const AdminPreview = ({ entity }: AdditionalFieldDelegateProps) => {
	const { loading, adminStatus, fetchAdminStatus } = useAdminStatus(entity.values.uid)

	useEffect(() => {
		fetchAdminStatus()
	}, [entity])

	return loading ? (
		<CircularProgress size="small" />
	) : adminStatus ? (
		<CheckCircleIcon size={16} color="success" />
	) : (
		<CancelIcon size={16} color="error" />
	)
}
