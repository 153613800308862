import State from "../StateProperty"
import { buildProperty } from "@firecms/core"
import type { Address } from "types/Components/Address"

const AddressProperty = buildProperty<Address>({
	name: "Address",
	description: "",
	dataType: "map",
	clearable: true,
	properties: {
		addressLine1: {
			name: "Address",
			dataType: "string",
			validation: {
				required: true,
			},
		},
		addressLine2: {
			name: "Unit",
			dataType: "string",
		},
		city: {
			name: "City",
			dataType: "string",
			validation: {
				required: true,
			},
		},
		state: State,
		zipCode: {
			name: "Zip",
			dataType: "number",
			validation: {
				required: true,
				// @ts-ignore
				length: 5,
				max: 99999,
			},
		},
		country: {
			name: "Country",
			dataType: "string",
			validation: {
				required: true,
			},
		},
	},
})

export default AddressProperty
