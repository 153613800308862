import { buildCollection } from "@firecms/core"
import type { ADMIN } from "types"
import {
	Firecrawl,
	OptimizedContent,
	LongSummaryCollection,
	ShortSummaryCollection,
	Requirements,
	Core,
	FormQuestions,
	FormLogic,
	Complete,
} from "./subcollections/extractSteps"
import DateUpdated from "../properties/DateUpdated"
import DateCreated from "../properties/DateCreated"

export const ExtractCollection = buildCollection<ADMIN.Extract>({
	id: "extract",
	name: "Extract Data",
	singularName: "Extract Data",
	description: "Extract data pipeline",
	path: "extract",
	group: "tools",
	icon: "format_list_numbered",
	textSearchEnabled: true,
	initialSort: ["dateUpdated", "desc"],
	permissions: () => ({
		read: true,
		edit: true,
		create: true,
		delete: true,
	}),
	propertiesOrder: [
		"url",
		"dateUpdated",
		"dateCreated",
		"subcollection:firecrawl",
		"subcollection:optimizedContent",
		"subcollection:longSummaryCollection",
		"subcollection:shortSummaryCollection",
		"subcollection:requirements",
		"subcollection:core",
		"subcollection:formQuestions",
		"subcollection:formLogic",
		"subcollection:complete",
	],
	subcollections: [
		Firecrawl,
		OptimizedContent,
		LongSummaryCollection,
		ShortSummaryCollection,
		Requirements,
		Core,
		FormQuestions,
		FormLogic,
		Complete,
	],
	properties: {
		url: {
			name: "URL",
			dataType: "string",
			url: true,
		},
		dateUpdated: DateUpdated,
		dateCreated: DateCreated,
	},
})
