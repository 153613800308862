import type { ChipColorScheme } from "@firecms/ui"

type colors = "gray" | "green" | "purple" | "yellow" | "blue" | "red"

type enumColorType = {
	[color in colors]: ChipColorScheme
}

export const enumColors: enumColorType = {
	gray: {
		color: "#808080",
		text: "white",
	},
	green: {
		color: "#409241",
		text: "white",
	},
	purple: {
		color: "#B265B2",
		text: "white",
	},
	yellow: {
		color: "#A47A23",
		text: "white",
	},
	blue: {
		color: "#108BBA",
		text: "white",
	},
	red: {
		color: "#BE6969",
		text: "white",
	},
}
export default enumColors
