import { type Field, defaultOperators } from "react-querybuilder"

const getOperators = (fieldName: string, { fieldData }: { fieldData: Field }) => {
	console.log("fieldData", fieldData)
	switch (fieldData.questionType) {
		case "yesNo":
			return [
				{ name: "in", value: "in", label: "In" },
				{ name: "notIn", value: "notIn", label: "Not In" },
				...defaultOperators.filter(op => ["in", "notIn"].includes(op.name)),
			]
		case "singleChoiceSelect":
		case "singleChoiceRadio":
		case "multipleChoiceCheckboxes":
			return [
				{ name: "in", value: "in", label: "In" },
				{ name: "notIn", value: "notIn", label: "Not In" },
				...defaultOperators.filter(op => ["in", "notIn"].includes(op.name)),
			]
	}
	return defaultOperators
}

export default getOperators
