import { useState, useEffect } from "react"
import { getFunctions, httpsCallable } from "firebase/functions"

type CheckAdminStatus = {
	userId: string
}

type SetAdminClaim = {
	email: string
	admin: boolean
}

type AdminCheckResponse = {
	admin?: boolean
	error?: string
}

type AdminToggleResponse = {
	message?: string
	error?: string
}

export const useAdminStatus = (uid: string, email?: string) => {
	const [loading, setLoading] = useState(true)
	const [adminStatus, setAdminStatus] = useState<boolean | null>(null)

	const fetchAdminStatus = async () => {
		setLoading(true)
		const functions = getFunctions()
		const checkAdminStatus = httpsCallable<CheckAdminStatus, AdminCheckResponse>(functions, "checkAdminStatus")
		try {
			const result = await checkAdminStatus({ userId: uid })
			setAdminStatus(result?.data?.admin || false)
		} catch (error) {
			console.error("Failed to check admin status:", error)
		} finally {
			setLoading(false)
		}
	}

	const toggleAdminStatus = async () => {
		if (!email) {
			console.warn("Email is required to toggle admin status")
			return
		}

		if (adminStatus === null) {
			console.warn("adminStatus has not been detemrined yet")
			return // Prevent toggling if adminStatus is not determined yet
		}

		setLoading(true)
		const functions = getFunctions()
		const setAdminClaim = httpsCallable<SetAdminClaim, AdminToggleResponse>(functions, "setAdminClaim")
		try {
			const result = await setAdminClaim({ email, admin: !adminStatus })
			if (result?.data?.error) {
				alert(result.data.error)
			} else {
				setAdminStatus(!adminStatus) // Toggle the admin status
			}
		} catch (error) {
			console.error("Failed to set admin claim:", error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (uid) {
			fetchAdminStatus()
		}
	}, [uid])

	return { loading, adminStatus, toggleAdmin: toggleAdminStatus, fetchAdminStatus }
}
