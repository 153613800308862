import type React from "react"
import { useEffect } from "react"
import type { FieldProps } from "@firecms/core"
import {
	Typography,
	CheckCircleIcon,
	LoadingButton,
	ExpandablePanel,
	AdminPanelSettingsIcon,
	type IconColor,
} from "@firecms/ui"
import { useAdminStatus } from "../../utils/useAdminStatus"

export const AdminDetail = ({ customProps }: FieldProps) => {
	const email = customProps.email
	const uid = customProps.uid
	const { loading, adminStatus, toggleAdmin } = useAdminStatus(uid, email)

	return (
		<ExpandablePanel title={<Title />} asField={true} initiallyExpanded={true}>
			<div className="flex items-center justify-between flex-wrap p-4">
				<div className="mt-2">
					{loading ? (
						<Typography>Checking Admin Status</Typography>
					) : adminStatus ? (
						<AdminLabel color="success" label="Is Admin" />
					) : (
						<AdminLabel color="error" label="Not Admin" />
					)}
				</div>
				<div className="mt-2">
					<LoadingButton
						onClick={toggleAdmin}
						name="admin"
						color="primary"
						variant="filled"
						size="small"
						loading={loading}
					>
						Toggle Admin Status
					</LoadingButton>
				</div>
			</div>
		</ExpandablePanel>
	)
}

const Title: React.FC = () => (
	<div className="flex items-center gap-2">
		<Typography variant="label" color="secondary">
			<AdminPanelSettingsIcon size="small" />
		</Typography>
		<Typography variant="label" color="secondary">
			Admin Status
		</Typography>
	</div>
)
type AdminLabelProps = {
	label: string
	color?: IconColor
}
const AdminLabel: React.FC<AdminLabelProps> = ({ label, color }) => {
	return (
		<div className="flex items-center gap-2">
			<CheckCircleIcon size={16} color={color} />
			<Typography variant="body1" color="secondary">
				{label}
			</Typography>
		</div>
	)
}
