import { buildProperty } from "@firecms/core"
import enumColors from "../../utils/enumColors"
import type { QAType } from "types/Enums/QAType"

const QATypeProperty = buildProperty<QAType>({
	name: "Type",
	dataType: "string",
	description:
		"Type of question or answer, defined here: https://docs.google.com/spreadsheets/d/178FdJjr3a74MUjMb6SvXZeV0sJQG_nD1rrULWKk8baY/edit?usp=sharing",
	enumValues: [
		{ id: "yesNo", label: "Yes/No", color: enumColors.blue },
		{ id: "singleChoiceSelect", label: "Dropdown: Single Choice", color: enumColors.red },
		{ id: "singleChoiceRadio", label: "Single Choice", color: enumColors.yellow },
		{ id: "multipleChoiceCheckboxes", label: "Multiple Choice", color: enumColors.green },
	],
})

export default QATypeProperty
