import { Tabs, Tab } from "@firecms/ui"
import { ValueEditor, type ValueEditorProps } from "react-querybuilder"

const CustomValueEditor = (props: ValueEditorProps) => {
	const { fieldData, value, handleOnChange } = props

	if (fieldData.inputType === "checkbox") {
		return (
			<div className="flex items-center gap-2">
				<Tabs value={value} onValueChange={handleOnChange}>
					{/* @ts-ignore */}
					<Tab value={true}>True</Tab>
					{/* @ts-ignore */}
					<Tab value={false}>False</Tab>
				</Tabs>
			</div>
		)
	}
	return <ValueEditor {...props} />
}

export default CustomValueEditor
