import { buildProperty } from "@firecms/core"

const DateUpdated = buildProperty({
	name: "Date Updated",
	dataType: "date",
	readOnly: true,
	autoValue: "on_update",
})

export default DateUpdated
