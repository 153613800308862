import { Typography } from "@firecms/ui"
import { useModeController } from "@firecms/core"
import { Highlight, themes } from "prism-react-renderer"
import type { RulesLogic } from "json-logic-js"

type CodeBlockType = {
	value: RulesLogic | null
}

const CodeBlock: React.FC<CodeBlockType> = ({ value }) => {
	const { mode } = useModeController()

	const formattedJson = JSON.stringify(value, null, 2)

	return (
		<Typography variant="caption">
			<Highlight theme={mode === "dark" ? themes.vsDark : themes.vsLight} code={formattedJson} language="js">
				{({ style, tokens, getLineProps, getTokenProps }) => (
					<pre style={style} className="overflow-x-auto p-2">
						{tokens.map((line, i) => (
							<div key={i} {...getLineProps({ line })}>
								{line.map((token, key) => (
									<span key={key} {...getTokenProps({ token })} />
								))}
							</div>
						))}
					</pre>
				)}
			</Highlight>
		</Typography>
	)
}

export default CodeBlock
