import { buildProperty } from "@firecms/core"

const DateCreated = buildProperty({
	name: "Date Created",
	dataType: "date",
	readOnly: true,
	autoValue: "on_create",
})

export default DateCreated
