import type { DataSource, Entity } from "@firecms/core"

export const deleteSecondaryEntities = async (
	dataSource: DataSource,
	selectedEntities: Entity<any>[],
	primaryEntity: Entity<any>
) => {
	const { deleteEntity } = dataSource

	for (const entity of selectedEntities) {
		if (entity.id === primaryEntity.id) {
			continue
		}

		await deleteEntity({ entity })
	}
}
