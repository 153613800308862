import { buildProperty } from "@firecms/core"
import type { Answer as AnswerType } from "types/Components/Answer"
import type { ADMIN } from "types"

const AnswerContent = buildProperty<AnswerType<ADMIN.Config>["content"]>({
	name: "Answer Content",
	dataType: "array",
	description: "User Answers",
	of: ({ propertyValue }) => {
		const type = propertyValue?.type
		const properties: Record<string, any> = {}
		switch (type) {
			case "yesNo":
				properties.boolean = buildProperty<boolean>({
					name: "Yes/No",
					dataType: "boolean",
				})
				break
			case "singleChoiceSelect":
			case "singleChoiceRadio":
				properties.option = buildProperty({
					name: "Choice",
					dataType: "map",
					properties: {
						id: {
							name: "id",
							dataType: "string",
							disabled: { hidden: true },
						},
						label: {
							name: "Label",
							description: "Human readable label",
							dataType: "string",
						},
						value: {
							name: "Value",
							description: "Actual value stored in db",
							dataType: "string",
						},
					},
				})
				break
			case "multipleChoiceCheckboxes":
				properties.options = buildProperty({
					name: "Choices",
					dataType: "array",
					of: {
						dataType: "map",
						properties: {
							id: {
								name: "id",
								dataType: "string",
								disabled: { hidden: true },
							},
							label: {
								name: "Label",
								description: "Human readable label",
								dataType: "string",
							},
							value: {
								name: "Selected",
								description: "Actual value stored in db",
								dataType: "boolean",
							},
						},
					},
				})
				break
			default:
				break
		}
		return {
			name: "Answer",
			dataType: "map",
			properties,
		}
	},
})
export default AnswerContent
